import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import PageHeader from '../component/pageheader'
import { LoadEffect } from '../component/loader'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Alerts} from '../component/notify'
import {ServerUrl, Token, config} from '../component/include'
import axios from 'axios'

import Error403 from './error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
 const Payment =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	
	
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);

const [onlinePayment, setOnlinePayment] = useState([])
const [bank, setBank] = useState([])

const fetchContent =()=>{
	setNotice({...notice, 
		isLoading: true
	});

	
	var sql ="Select * from tbl_payment_gateway";    
	const fd = new FormData();
        fd.append('jwt', Token);
	fd.append("sql", sql);
	let url = ServerUrl+'/fetchBySql_controller'
	
	axios.post(url, fd, config).then(result=>{ 
		setBank(result.data[0])
		setOnlinePayment(result.data[1])
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
		setNotice({...notice, 
			isLoading: false
		});
	}) 

}


	const handleChangeBank =(event)=>{
		let {name, value} = event.target;
		if(name==='status'){
			value = value==='true'?'false':'true'
		}
		setBank({...bank, [name] : value });
	}

	const handleChangeOnline =(event)=>{
		let {name, value} = event.target;
		if(name==='status'){
			value = value==='true'?'false':'true'
		}
		setOnlinePayment({...onlinePayment, [name] : value });
	}
	
	function updatePayment(formData){  
		setNotice({...notice, 
		  isLoading: true})     
		  
		  const fd = new FormData();
        fd.append('jwt', Token);
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		  let url = ServerUrl+'/update_controller/tbl_payment_gateway'  
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='info'){
				
				Alerts('Saved!', 'default', response.data.message)
					} else{
				Alerts('Error!', 'danger', JSON.stringify(response.data))
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				fetchContent()
				setNotice({...notice, 
					isLoading: false
				})
			}) 
	}
	
	const handleChangeInstruction =(data)=>{
		setBank({...bank, details : data });
	}
  
	
	useEffect(()=>{
		
        //setIsAuthenticated(verifyRouter(location.pathname))
        fetchContent()
       },[]);

        return (<>
    
			<div  className="main-body">
			{isAuthenticated?   <div className="page-wrapper">
						  {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
       <PageHeader title="Payment Gateway"
	   				menu="Payment Gateway"
					   />


{/* The Page Body start here */}
<div className="page-body">
		
			

<div className="row no-print ">
							

				<div className="col-sm-12">
			
				<div className="card z-depth-0">
					<div className="card-header">
						<h5><i className="fa fa-edit" ></i> Bank Payment</h5>
						<div className="card-header-right">
							<ul className="list-unstyled card-option">
								<li><i className="feather icon-maximize full-card"></i></li>
								<li><i className="feather icon-minus minimize-card"></i></li>
								<li><i className="feather icon-trash-2 close-card"></i></li>
							</ul>
						</div>
					</div><div className="card-block">
					<div className="card z-depth-0">
					<div className="card-block">
					{bank.length!==0?
					<div className="row">
			
								<section className="col-md-12">
								<div className="form-group">
						<label> Display Name<span style={{color:'red'}}>*</span></label>
						<input type="text" onChange={handleChangeBank} name="title" value={bank.title} className='form-control ' />
						</div>
					
					</section>   
					<section className="col-md-12">
								<div className="form-group">
						<label> Payment Instruction<span style={{color:'red'}}>*</span></label>
						 <QuillText value={bank.details} onChange={handleChangeInstruction} />  
						</div>
					
					</section> 
					
					
							</div>:''}	
			
			
				<footer className="pull-right">
				
				<button type="button" id="submit" onClick={()=>updatePayment(bank)}  className="btn btn-inverse "><i className="fa fa-save"></i> Save</button>
											</footer>				
										</div>
										</div>
										</div></div>
			</div>
			
			</div>







			<div className="row no-print ">
							

							<div className="col-sm-12">
						
							<div className="card z-depth-0">
								<div className="card-header">
									<h5><i className="fa fa-edit" ></i> Online Payment</h5>
									<div className="card-header-right">
										<ul className="list-unstyled card-option">
											<li><i className="feather icon-maximize full-card"></i></li>
											<li><i className="feather icon-minus minimize-card"></i></li>
											<li><i className="feather icon-trash-2 close-card"></i></li>
										</ul>
									</div>
								</div><div className="card-block">
								<div className="card z-depth-0">
								<div className="card-block">
								
								<div className="row">
						
											<section className="col-md-12">
											<div className="form-group">
									<label> Display Name <span style={{color:'red'}}>*</span></label>
									<input type="text" onChange={handleChangeOnline}   name="title" value={onlinePayment.title} className='form-control ' />
									</div>
								
								</section>   
								<section className="col-md-12">
											<div className="form-group">
									<label> Details<span style={{color:'red'}}>*</span></label>
									<input type="text" onChange={handleChangeOnline}  name="details" value={onlinePayment.details} className='form-control ' />
									</div>
								
								</section> 
								
								
										</div>	
						
						
							<footer className="pull-right">
							
							<button type="button" id="submit" onClick={()=>updatePayment(onlinePayment)} className="btn btn-inverse "><i className="fa fa-save"></i> Save</button>
														</footer>				
													</div>
													</div>
													</div></div>
						</div>
						
						</div>
			


                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 

 );
        
}

export default React.memo(Payment) 